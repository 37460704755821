<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Day information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Type" label-position="inside">
                        <b-select v-model="day.type" expanded>
                            <option value="pre">Pre-Extension</option>
                            <option value="trip">Main Trip</option>
                            <option value="post">Post-Extension</option>
                            <option value="hidden">Private/Hidden on Website</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Title" label-position="inside">
                        <b-input v-model="day.title"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Date" label-position="inside">
                        <b-input type="date" v-model="day.day_on"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Number" label-position="inside">
                        <b-input v-model="day.number"/>
                    </b-field>
                </div>
            </div>
            <div class="columns mb-3">
                <div class="column">
                    <b-field label="Description" label-position="inside">
                        <b-input type="textarea" v-model="day.description"/>
                    </b-field>
                </div>
            </div>
            <div class="columns mb-3" v-if="day.id">
                <div class="column">
                    <b-switch v-model="day.redate">Update dates on all events for this day on Save.</b-switch>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        name: 'DayForm',
        props: {
            day: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList}
    };

</script>

<style scoped>

</style>
